import { useEffect, useRef, useState } from 'react'
import { OutlinedButton } from '@jsluna/button'
import { ProgressIndicator, ProgressBar } from '@jsluna/progress'
import { FormGroup } from '@jsluna/form'

import { useComponentContext } from '../../ComponentContext'
import { removeFromArray } from '../../../../../utils/array'
import ImagePreview from './ImagePreview'
import {
  fileUpload,
  fileRemoval,
  getImagesURLs
} from '../../../../../api/reportingApi'
import { useApiClient } from '../../../../../context/AppContext'
import { IImageUploadResponse } from '../../../../../types/IImageUploadResponse'

function UploadComponent({
  label,
  id,
  multiple,
  text,
  error: validationError,
  answer,
  defaultValue,
}: any) {

  if( defaultValue && !answer){
    answer = defaultValue
  }

  const { handleUpdate, enableNext, disableNext } = useComponentContext()
  const apiClient = useApiClient()
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState((answer && JSON.parse(answer)) || [])
  const [error, setError] = useState('')
  const fileInputRef: any = useRef(null)

  const updateImagesURLs = async () => {
    const res: any = await getImagesURLs(apiClient, answer)
    setImages(res)
  }

  useEffect(() => {
    if (answer != null) {
      updateImagesURLs()
    }
  }, [])

  const handleUpload = async (e: any) => {
    e.preventDefault()

    const files = Array.from(e.target.files)

    disableNext()
    setLoading(true)

    const updateImages = () => {
      files.forEach((file: any) => {
        response.forEach((res: IImageUploadResponse) => {
          if (res.fileName == file.name && !res.hasError) {
            setImages((prevImages: any) => [
              ...prevImages,
              {
                name: res.uploadReference,
                previewUrl: URL.createObjectURL(file)
              }
            ])
          }
        })
      })
    }

    const countFailedImages = (response: any[]) => {
      let failedImgNumber = 0
      response.forEach(res => {
        if (res.hasError) failedImgNumber++
      })

      if (failedImgNumber > 0)
        setError(`${failedImgNumber} images failed to upload`)
    }

    const response: any = await fileUpload(apiClient, files, images)

    if (!response != null || undefined) {
      setError('')
      updateImages()
      countFailedImages(response!)
    } else {
      setError('An error has occurred')
    }

    enableNext()
    setLoading(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  const removeImage = async (image: any) => {
    const response: any = await fileRemoval(apiClient, image?.name)

    if (!response.error) {
      URL.revokeObjectURL(image.previewUrl)
      setImages((prevState: any) => removeFromArray(prevState, image))
    } else {
      setError(response.message ?? 'An error has occurred removing the image')
    }
  }

  useEffect(() => setError(validationError), [validationError])

  useEffect(() => {
    if (images.length > 0) {
      let imagesDetails: string[] = []
      if(images[0]?.name != null || images[0]?.name != undefined){
        images.forEach((image: any) => {
          imagesDetails.unshift(image.name)
        })
      }
      else {
        imagesDetails = images
      }
      
      handleUpdate({
        id,
        value: JSON.stringify(imagesDetails)
      })
    }
    else{
      handleUpdate({
       id,
       value: undefined
      })
    }
  }, [images])

  return (
    <>
      <div>
        <FormGroup name="s-files-upload-button" error={error} label={label}>
          <OutlinedButton
            element="label"
            className="fileinputlabel"
            htmlFor="s-files-upload-button"
            disabled={loading}
            fullWidth
          >
            <input
              type="file"
              id="s-files-upload-button"
              name={label}
              multiple={multiple}
              onChange={handleUpload}
              disabled={loading}
              className="fileinput"
              ref={fileInputRef}
            />
            {text}
          </OutlinedButton>
        </FormGroup>
      </div>
      <div id="s-files-upload-message">
        {loading && (
          <ProgressIndicator loading preventFocus>
            <ProgressBar
              className="ln-u-push-bottom-lg"
              title="Regular sized progress indicator demo"
            />
          </ProgressIndicator>
        )}
        {!loading && <ImagePreview removeImage={removeImage} images={images} />}
      </div>
    </>
  )
}

export default UploadComponent
